var ClientIP = "";

function getUserIP(onNewIP) {
    var myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    var pc = new myPeerConnection({
            iceServers: []
        }),
        noop = function() {},
        localIPs = {},
        ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
        key;

    function iterateIP(ip) {
        if (!localIPs[ip]) onNewIP(ip);
        localIPs[ip] = true;
    }
    pc.createDataChannel("");
    pc.createOffer().then(function(sdp) {
        sdp.sdp.split('\n').forEach(function(line) {
            if (line.indexOf('candidate') < 0) return;
            line.match(ipRegex).forEach(iterateIP);
        });
        pc.setLocalDescription(sdp, noop, noop);
    }).catch(function(reason) {});
    pc.onicecandidate = function(ice) {
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
        ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
}
getUserIP(function(ip) {
    if (ip.match(/^(192\.168\.|169\.254\.|10\.|172\.(1[6-9]|2\d|3[01]))/))
        ClientIP = ip;
});

$('body').on('click', '.server_side_page button.buttons-columnVisibility',  function(e) {
    e.preventDefault();
    const index = parseInt($(this).attr('data-cv-idx')) + 1;
    if($(this).hasClass('active')) {
        $(".server_side_page table tbody tr td:nth-child(" + index + ")").removeClass('d-none');
    } else {
        $(".server_side_page table tbody tr td:nth-child(" + index + ")").addClass('d-none'); 
    }
});

$('body').on('click', 'table tbody tr td a.close-btn', function () {
    $(this).parents('tr').remove();
})
